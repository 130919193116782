import {
  type ApolloQueryResult,
  type DocumentNode,
  type FetchResult,
  type MutationOptions,
  type OperationVariables,
  type QueryOptions,
} from "@apollo/client";

import { removeUndefined } from "@utils/generic";

import { api } from "@api/apollo";

export const client = {
  query: <T>(
    query: DocumentNode,
    variables: OperationVariables = {},
    options?: Omit<QueryOptions<OperationVariables>, "query" | "variables">,
  ): Promise<Query<T>> =>
    (api.client as NonNullable<typeof api.client>).query({
      ...options,
      query,
      variables,
    }),
  mutate: (
    mutation: DocumentNode,
    variables: OperationVariables = {},
    options?: Omit<MutationOptions<OperationVariables>, "variables">,
  ): Promise<Mutation> =>
    (api.client as NonNullable<typeof api.client>).mutate({
      ...options,
      mutation,
      variables: removeUndefined(variables),
    }),
};

export type Query<T> = ApolloQueryResult<T>;
export type Mutation = FetchResult<OperationVariables>;
