import { gql } from "@apollo/client";
import { type OtpContactType, type User, type UserLanguage } from "@technis/shared";

const userData = `
  id
  name
  lang
  email
  firstName
  lastName
  organizationIds
  dateFormat
  hasStartedTutorial
  picture
  profileId
  phone
  isPhoneVerified
  isEmailVerified
  otpContact
`;

export const ME_DATA_QUERY = gql`
  query userData {
      me {
          ${userData}
      }
  }
`;

export const USERS_LIST = gql`
  query allUsers {
    allUsers {
      id
      firstName
      lastName
      phone
      email
      picture
    }
  }
`;

export const USER_BY_ID = gql`
  query userById($userId: String!) {
      userById(userId: $userId) {
          ${userData}
      }
  }
`;

export const UPDATE_USER_PROFILE = gql`
  mutation updateUserProfile($profile: UserProfileITC) {
    updateUserProfile(profile: $profile) {
      id
    }
  }
`;

export const UPDATE_USER_SECURITY = gql`
  mutation updateUserSecurity($oldPassword: String!, $newPassword: String!, $otpContact: OtpContactType) {
    updatePassword(oldPassword: $oldPassword, newPassword: $newPassword)
    updateOtpContact(otpContact: $otpContact) {
      id
    }
  }
`;

export const UPDATE_OTP_CONTACT = gql`
  mutation updateOtpContact($otpContact: OtpContactType) {
    updateOtpContact(otpContact: $otpContact) {
      id
    }
  }
`;

export interface UserData {
  dateFormat: string;
  email: string;
  firstName: string;
  hasStartedTutorial: boolean;
  id: string;
  isEmailVerified: boolean;
  isPhoneVerified: boolean;
  lang?: UserLanguage;
  lastName: string;
  name: string;
  organizationIds: number[];
  otpContact: OtpContactType | null;
  phone: string;
  picture?: string;
  profileId?: number;
}

export type MeQueryData = {
  me: UserData;
};

export type UsersList = {
  allUsers: User[];
};

export type UserById = {
  userById: UserData;
};
