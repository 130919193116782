/* eslint-disable max-lines */
import { type QueryResult, useLazyQuery, useQuery } from "@apollo/client";
import { type Dashboard, EventMetrics, isDefined, type Page, Permissions } from "@technis/shared";
import { isUndefined } from "lodash";
import React, { createElement, useEffect, useState } from "react";
import { lazily } from "react-lazily";
import { useDispatch, useSelector } from "react-redux";
import { createBrowserRouter, Navigate, Outlet, type RouteObject, RouterProvider, useParams } from "react-router-dom";
import { useDeepCompareEffect } from "react-use";

import { PAGES, TOPICS, Tracking } from "@namespaces/tracking";
import { sortByOrder } from "@utils/generic";

import { ThemeContextProvider } from "@common/Theme";
import { BreadcrumbRouteName } from "@common/enums/breadcrumbs";
import { ClientFetchPolicy } from "@common/enums/network/network";
import { getAlertRights } from "@common/helpers/getAlertRights";
import { getDashboardPagePath } from "@common/helpers/routing/getDashboardPagePath";
import { isInstallationsShown } from "@common/helpers/sidebar";
import { getTokenData } from "@common/helpers/token";
import { parseUserManagementPermissions } from "@common/helpers/userManagement/parseUserManagementPermissions";
import { type SideBarTab } from "@common/interfaces/sidebar/sideBarTab";
import { onboardingComponents } from "@common/maps/onboarding";

import { Breadcrumbs } from "@components/Breadcrumbs";
import { CookiesModal } from "@components/CookiesModal";
import { ExportDataActions } from "@components/ExportData/ExportDataActions/ExportDataActions";
import { InstallationPageOptions } from "@components/InstallationDetails/InstallationPageOptions/InstallationPageOptions";
import { MainLayout } from "@components/Layouts/MainLayout/MainLayout";
import { AlertActions } from "@components/Settings";
import { UserManagementDefaultPage } from "@components/Settings/UsersManagement/ManagementProfileGuard/UserManagementDefaultPage";
import { UsersManagementWrapper } from "@components/Settings/UsersManagement/UsersManagementWrapper/UsersManagementWrapper";
import { useAvailableSettingsTabs } from "@components/Settings/hooks/useAvailableSettingsTabs";
import { ShortLink } from "@components/ShortLink";
import { ToastHandler } from "@components/ToastHandler";
import { UserProfileTabs } from "@components/UserProfile/UserProfileTabs/UserProfileTabs";

import { getTranslationDetails } from "@redux/translation/translation.actions";
import { updateUser } from "@redux/user/user.slice";

import { PublicRoute } from "@routes/PublicRoute";
import { SensorRoute } from "@routes/SensorRoute";

import { ALL_DASHBOARDS_QUERY, type AllDashboardQueryData } from "@services/dashboardService";
import { ALL_EVENTS_QUERY, type AllEventsQueryData } from "@services/eventService";
import { PAGES_BY_DASHBOARD_ID, type PagesByDashboardIdResult } from "@services/pageService";
import { PROFILE_DATA_QUERY, type ProfileData } from "@services/profileService";
import { ME_DATA_QUERY, type MeQueryData } from "@services/userService";

import { type RootState } from "@store/rootReducer";

import { DashboardV2 } from "@views/DashboardV2/DashboardV2";

import { LoggedRoute } from "./LoggedRoute";
import { RoutePath } from "./routePath";

const { UserManagementGeneral } = lazily(
  () => import("@views/Settings/UserManagement/UserDetails/UserManagementGeneral/UserManagementGeneral"),
);
const { UserManagementPreferences } = lazily(
  () => import("@views/Settings/UserManagement/UserDetails/UserManagementPreferences/UserManagementPreferences"),
);
const { UserManagementSecurity } = lazily(
  () => import("@views/Settings/UserManagement/UserDetails/UserManagementSecurity/UserManagementSecurity"),
);
const { UsersList } = lazily(() => import("@views/Settings/UserManagement/UsersList/UsersList"));
const { UserProfileGeneral } = lazily(() => import("@views/UserProfile/UserProfileGeneral/UserProfileGeneral"));
const { UserProfileWrapper } = lazily(() => import("@components/UserProfile/UserProfileWrapper/UserProfileWrapper"));
const { UserProfilePreferences } = lazily(() => import("@views/UserProfile/UserProfilePreferences/UserProfilePreferences"));
const { UserProfileSecurity } = lazily(() => import("@views/UserProfile/UserProfileSecurity/UserProfileSecurity"));
const { Onboarding } = lazily(() => import("@components/onboarding"));
const { Invitation } = lazily(() => import("@views/Invitation"));
const { NotFound } = lazily(() => import("@views/NotFound/NotFound"));
const { SetNewPassword } = lazily(() => import("@views/ResetPassword"));
const { Login } = lazily(() => import("@views/Login/Login"));
const { AirQualityInstallations } = lazily(() => import("@views/AirQualityInstallations/AirQualityInstallations"));
const { AirQualityInstallationDetails } = lazily(() => import("@views/AirQualityInstallationDetails"));
const { InstallationDetailsFilters } = lazily(() => import("@components/InstallationDetails"));
const { DashboardPageWrapper } = lazily(() => import("@routes/DashboardPageWrapper"));
const { TutorialContainer } = lazily(() => import("@views/Tutorial/TutorialContainer/TutorialContainer"));
const { CustomPage } = lazily(() => import("@views/CustomPage"));
const { HelpCenter } = lazily(() => import("@views/HelpCenter/HelpCenter"));
const { CountingInstallationDetails } = lazily(() => import("@views/CountingInstallationDetails"));
const { CountingInstallations } = lazily(() => import("@views/CountingInstallations/CountingInstallations"));
const { SettingsWrapper } = lazily(() => import("@components/Settings/SettingsWrapper"));
const { AlertDetails } = lazily(() => import("@views/Settings"));
const { AlertDetailsData } = lazily(() => import("@components/Settings/Alert/AlertDetails/AlertDetailsData"));
const { AlertsList } = lazily(() => import("@views/Settings"));
const { ExportData } = lazily(() => import("@views/ExportData/ExportData"));
const { OrganizationSettings } = lazily(() => import("@views/Settings/Organization/OrganizationSettings"));

const getActiveIconUrl = (icon: string): string =>
  `${icon.slice(0, Math.max(0, icon.lastIndexOf(".")))}-selected${icon.slice(Math.max(0, icon.lastIndexOf(".")))}`;

const getSidebarConfig = (pages: Page[]): SideBarTab[] =>
  pages.map((page) => ({
    text: page.name,
    link: getDashboardPagePath(page.dashboardId, page.id),
    icon: page.icon,
    activeIcon: getActiveIconUrl(page.icon),
    isActive: false,
    pageId: page.id,
    order: page.order,
  }));

// eslint-disable-next-line sonarjs/cognitive-complexity
export const AppRoutes = (): JSX.Element => {
  const token = useSelector((state: RootState) => state.auth.token);
  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const { pageId } = useParams();

  const { data: userData, refetch: userMeRefetch } = useQuery<MeQueryData>(ME_DATA_QUERY, {
    fetchPolicy: ClientFetchPolicy.NETWORK_ONLY,
    skip: !token || !isLoggedIn,
  });

  const user = userData?.me;

  const { data: profileData, error } = useQuery<ProfileData>(PROFILE_DATA_QUERY, {
    fetchPolicy: "network-only",
    variables: {
      id: user?.profileId,
    },
    skip: !user || !token || !isLoggedIn,
  });

  const alertRights = getAlertRights(profileData, error);

  const dashboardsData = useQuery<AllDashboardQueryData>(ALL_DASHBOARDS_QUERY, {
    skip: !user,
  });

  const isExportDataAllowed = profileData?.profileById.rights.dashboard.report?.create;
  const isExportDataShown = isExportDataAllowed || isUndefined(isExportDataAllowed);

  useEffect(() => {
    if (token) {
      userMeRefetch();
      dashboardsData.refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const [sidebarConfig, setSidebarConfig] = useState<SideBarTab[]>([]);

  const dispatch = useDispatch();

  const allDashboards = dashboardsData.data?.allDashboards || [];
  // TODO: change when users and organizations can have multiple dashboards
  const userDashboard = allDashboards.find((dashboard) => dashboard.userId === user?.id);
  const organizationDashboard = allDashboards.find(
    (dashboard) => dashboard.organizationId && user?.organizationIds?.includes(dashboard.organizationId),
  );

  const firstDashboard = (userDashboard ?? organizationDashboard) as Dashboard;

  const [fetchPages, { data }] = useLazyQuery<PagesByDashboardIdResult>(PAGES_BY_DASHBOARD_ID);

  const { data: allEventsData } = useQuery<AllEventsQueryData>(ALL_EVENTS_QUERY, {
    fetchPolicy: "network-only",
    skip: !userData,
  });

  useEffect(() => {
    dispatch(getTranslationDetails());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDeepCompareEffect(() => {
    if (user && token) {
      const {
        claims: { adminUserId },
      } = getTokenData(token);
      Tracking.setUser(user, adminUserId);
      return;
    }
    setSidebarConfig([]);
  }, [user, token]);

  useDeepCompareEffect(() => {
    if (isDefined(firstDashboard?.id)) {
      const triggerFetch = async (): Promise<QueryResult> =>
        await fetchPages({
          variables: {
            id: firstDashboard.id,
          },
        });

      triggerFetch();
    }
  }, [firstDashboard]);

  useDeepCompareEffect(() => {
    if (user) {
      dispatch(updateUser(user));
    }
  }, [dispatch, user]);

  const allPages = data?.pagesByDashboardId;

  const [organizationId] = userData?.me.organizationIds ?? [];

  const { isAirQualityShown, isCountingShown } = isInstallationsShown(allEventsData?.allEvents);

  useDeepCompareEffect(() => {
    if (allPages) {
      const sortedPages = sortByOrder(allPages);

      const newConfig = getSidebarConfig(sortedPages);
      setSidebarConfig([...newConfig]);
    } else {
      setSidebarConfig([]);
    }
  }, [allPages]);

  const { steps: onboardingSteps } = useSelector((state: RootState) => state.userOnboarding);
  const {
    isUserManagementAvailable,
    isUserManagementEditable,
    isUserManagementRouteActive,
    isUserManagementDeletable,
    userManagementMode,
  } = parseUserManagementPermissions(profileData?.profileById);
  const isOrganizationDataAvailable = Boolean(profileData?.profileById.rights.organization.general.read);
  const isOrganizationRouteActive = isOrganizationDataAvailable || isUndefined(isOrganizationDataAvailable);

  const isAlertDetailsAvailable = alertRights[Permissions.READ];
  const isAlertDetailsRouteActive = isAlertDetailsAvailable || isUndefined(isAlertDetailsAvailable);
  const isAlertUpsertAvailable = alertRights[Permissions.CREATE] || alertRights[Permissions.EDIT];

  const isSettingsPageRouteActive = isAlertDetailsRouteActive || isOrganizationRouteActive || isUserManagementRouteActive;

  const firstAvailableSettingsTab = useAvailableSettingsTabs({
    isAlertDetailsAvailable,
    isOrganizationDataAvailable,
    isUserManagementAvailable,
  })[0];

  const airQualityRoutes = [
    {
      path: RoutePath.AIR_QUALITY,
      handle: {
        breadcrumb: BreadcrumbRouteName.INSTALLATION__AIR_QUALITY,
      },
      children: [
        {
          path: RoutePath.AIR_QUALITY,
          element: (
            <LoggedRoute name={PAGES.AIR_QUALITY_INSTALLATION_LIST} topic={TOPICS.DASHBOARD}>
              <SensorRoute isCountingShown={isCountingShown} isAirQualityShown={isAirQualityShown} requestedRoute={RoutePath.AIR_QUALITY}>
                <AirQualityInstallations />
              </SensorRoute>
            </LoggedRoute>
          ),
        },
        {
          path: RoutePath.AIR_QUALITY_INSTALLATION,
          element: (
            <LoggedRoute name={PAGES.AIR_QUALITY_INSTALLATION_DETAIL} topic={TOPICS.DASHBOARD}>
              <SensorRoute
                isCountingShown={isCountingShown}
                isAirQualityShown={isAirQualityShown}
                requestedRoute={RoutePath.AIR_QUALITY_INSTALLATION}
              >
                <InstallationDetailsFilters>
                  <AirQualityInstallationDetails organizationId={organizationId} />
                </InstallationDetailsFilters>
              </SensorRoute>
            </LoggedRoute>
          ),
          handle: {
            breadcrumb: BreadcrumbRouteName.INSTALLATION__AIR_QUALITY__DETAILS,
            breadcrumbChildren: <InstallationPageOptions metric={EventMetrics.ATMOSPHERE} isHidden={!isExportDataShown} />,
          },
        },
      ],
    },
  ];

  const countingRoutes = [
    {
      path: RoutePath.COUNTING,
      handle: {
        breadcrumb: BreadcrumbRouteName.INSTALLATION__COUNTING,
      },
      children: [
        {
          path: RoutePath.COUNTING,
          element: (
            <LoggedRoute name={PAGES.COUNTING_INSTALLATION_LIST} topic={TOPICS.DASHBOARD}>
              <SensorRoute isCountingShown={isCountingShown} isAirQualityShown={isAirQualityShown} requestedRoute={RoutePath.COUNTING}>
                <CountingInstallations />
              </SensorRoute>
            </LoggedRoute>
          ),
        },
        {
          path: RoutePath.COUNTING_INSTALLATION,
          element: (
            <LoggedRoute name={PAGES.COUNTING_INSTALLATION_DETAIL} topic={TOPICS.DASHBOARD}>
              <InstallationDetailsFilters>
                <SensorRoute
                  isCountingShown={isCountingShown}
                  isAirQualityShown={isAirQualityShown}
                  requestedRoute={RoutePath.COUNTING_INSTALLATION}
                >
                  <CountingInstallationDetails organizationId={organizationId} />
                </SensorRoute>
              </InstallationDetailsFilters>
            </LoggedRoute>
          ),
          handle: {
            breadcrumb: BreadcrumbRouteName.INSTALLATION__COUNTING__DETAILS,
            breadcrumbChildren: <InstallationPageOptions metric={EventMetrics.COUNTING} isHidden={!isExportDataShown} />,
          },
        },
      ],
    },
  ];

  const router = createBrowserRouter([
    {
      path: RoutePath.ROOT,
      element: <Navigate to={RoutePath.HOME} />,
    },
    {
      path: RoutePath.LOGIN,
      element: (
        <PublicRoute name={PAGES.LOGIN} topic={TOPICS.AUTH}>
          <Login />
        </PublicRoute>
      ),
    },
    {
      path: RoutePath.SHORT_LINK,
      element: <ShortLink />,
    },
    {
      path: RoutePath.INVITATION,
      element: (
        <PublicRoute name={PAGES.ONBOARDING_INVITATION} topic={TOPICS.ONBOARDING}>
          <Invitation />
        </PublicRoute>
      ),
    },
    {
      path: RoutePath.ONBOARDING,
      element: <Onboarding />,
      children: [
        {
          path: RoutePath.ONBOARDING,
          element: <Navigate to={onboardingSteps[0].routePath} />,
        },
        ...onboardingSteps.map(({ routePath, componentName }) => ({
          path: routePath,
          element: (
            <PublicRoute name={componentName} topic={TOPICS.ONBOARDING}>
              {createElement(onboardingComponents[componentName])}
            </PublicRoute>
          ),
        })),
        {
          path: `${RoutePath.ONBOARDING}/*`,
          element: <Navigate to={RoutePath.ONBOARDING} />,
        },
      ],
    },
    {
      element: (
        <MainLayout
          logo={firstDashboard?.icon}
          sidebarConfig={sidebarConfig}
          profileData={profileData}
          isAirQualityShown={isAirQualityShown}
          isCountingShown={isCountingShown}
          isSettingsShown={isSettingsPageRouteActive}
          isExportDataShown={isExportDataShown}
          organizationId={user?.organizationIds[0]}
          onStartTutorial={userMeRefetch}
        >
          <Breadcrumbs customPageData={sidebarConfig} />
        </MainLayout>
      ),
      children: [
        ...airQualityRoutes,
        ...countingRoutes,
        {
          path: RoutePath.HOME2,
          element: (
            <LoggedRoute name={PAGES.HOME2} topic={TOPICS.HOME2}>
              <DashboardV2 />
            </LoggedRoute>
          ),
          handle: {
            breadcrumb: BreadcrumbRouteName.HOME,
          },
        },
        {
          path: RoutePath.HOME,
          element: (
            <LoggedRoute name={PAGES.HOME} topic={TOPICS.HOME}>
              <DashboardPageWrapper
                profileData={profileData}
                allPages={allPages}
                isAirQualityShown={isAirQualityShown}
                isCountingShown={isCountingShown}
              />
            </LoggedRoute>
          ),
          handle: {
            breadcrumb: BreadcrumbRouteName.HOME,
          },
        },
        {
          path: RoutePath.TUTORIAL,
          element: (
            <LoggedRoute name={PAGES.TUTORIAL_INSTALLATIONS} topic={TOPICS.TUTORIAL}>
              <TutorialContainer />
            </LoggedRoute>
          ),
        },
        {
          path: RoutePath.PAGE,
          handle: {
            breadcrumb: BreadcrumbRouteName.CUSTOM_PAGE,
          },
          element: (
            // eslint-disable-next-line new-cap
            <LoggedRoute name={PAGES.CUSTOM_DASHBOARD_PAGE(allPages?.[Number(pageId)]?.name || "Unknown")} topic={TOPICS.CUSTOM_DASHBOARD}>
              <CustomPage organizationId={organizationId} />
            </LoggedRoute>
          ),
        },
        {
          path: RoutePath.HELP_CENTER,
          handle: {
            breadcrumb: BreadcrumbRouteName.HELP_CENTER,
          },
          element: (
            <LoggedRoute name={PAGES.HELP_CENTER} topic={TOPICS.HELP}>
              <HelpCenter />
            </LoggedRoute>
          ),
        },
        isSettingsPageRouteActive && {
          path: RoutePath.SETTINGS,
          handle: {
            breadcrumb: BreadcrumbRouteName.SETTINGS,
          },
          element: (
            <LoggedRoute name={PAGES.SETTINGS} topic={TOPICS.SETTINGS}>
              <Outlet />
            </LoggedRoute>
          ),
          children: firstAvailableSettingsTab
            ? [
                {
                  path: RoutePath.SETTINGS,
                  element: <Navigate replace to={firstAvailableSettingsTab.routePath} />,
                },
                {
                  element: (
                    <SettingsWrapper
                      isUserManagementAvailable={isUserManagementAvailable}
                      isAlertDetailsAvailable={isAlertDetailsAvailable}
                    />
                  ),
                },
                {
                  element: (
                    <SettingsWrapper
                      isOrganizationDataAvailable={isOrganizationDataAvailable}
                      isUserManagementAvailable={isUserManagementAvailable}
                      isAlertDetailsAvailable={isAlertDetailsAvailable}
                    />
                  ),
                  children: [
                    isAlertDetailsRouteActive && {
                      path: RoutePath.SETTINGS_ALERTS,
                      element: <AlertsList alertRights={alertRights} />,
                      handle: {
                        breadcrumb: BreadcrumbRouteName.SETTINGS__ALERTS,
                      },
                    },
                    isUserManagementRouteActive && {
                      path: RoutePath.SETTINGS_USER_MANAGEMENT,
                      element: <UsersList hasDeletePermission={isUserManagementDeletable} />,
                      handle: {
                        breadcrumb: BreadcrumbRouteName.SETTINGS_USER_MANAGEMENT,
                      },
                    },
                    isOrganizationRouteActive && {
                      path: RoutePath.SETTINGS_ORGANIZATION,
                      element: (
                        <OrganizationSettings
                          organizationId={organizationId}
                          isReadOnly={!profileData?.profileById.rights.organization.general.edit}
                        />
                      ),
                      handle: {
                        breadcrumb: BreadcrumbRouteName.SETTINGS_ORGANIZATION,
                      },
                    },
                    {
                      path: `${RoutePath.SETTINGS}/*`,
                      element: <Navigate replace to={RoutePath.NOT_FOUND} />,
                    },
                  ],
                },
                isAlertUpsertAvailable && {
                  path: RoutePath.SETTINGS_ALERTS,
                  handle: {
                    breadcrumb: BreadcrumbRouteName.SETTINGS__ALERTS,
                  },
                  children: [
                    alertRights[Permissions.CREATE] && {
                      path: RoutePath.SETTINGS_ALERTS_NEW,
                      element: <AlertDetails currentUserId={user?.id} />,
                      handle: {
                        breadcrumb: BreadcrumbRouteName.SETTINGS__ALERTS__NEW,
                        breadcrumbChildren: <AlertActions isNewAlert organizationId={organizationId} />,
                      },
                    },
                    alertRights[Permissions.EDIT] && {
                      path: RoutePath.SETTINGS_ALERTS_UPDATE,
                      element: (
                        <AlertDetailsData>
                          <AlertDetails currentUserId={user?.id} />
                        </AlertDetailsData>
                      ),
                      handle: {
                        breadcrumb: BreadcrumbRouteName.SETTINGS__ALERTS__UPDATE,
                        breadcrumbChildren: <AlertActions organizationId={organizationId} />,
                      },
                    },
                  ],
                },
                isUserManagementRouteActive && {
                  path: RoutePath.SETTINGS_USER_MANAGEMENT,
                  handle: {
                    breadcrumb: BreadcrumbRouteName.SETTINGS_USER_MANAGEMENT,
                  },
                  children: [
                    {
                      path: RoutePath.SETTINGS_USER_MANAGEMENT_PROFILE,
                      handle: {
                        breadcrumb: BreadcrumbRouteName.SETTINGS_USER_MANAGEMENT_PROFILE,
                      },
                      element: (
                        <LoggedRoute name={PAGES.USER_PROFILE} topic={TOPICS.USER_PROFILE}>
                          <UsersManagementWrapper>
                            <Outlet />
                          </UsersManagementWrapper>
                        </LoggedRoute>
                      ),
                      children: [
                        {
                          path: RoutePath.SETTINGS_USER_MANAGEMENT_GENERAL,
                          element: <UserManagementGeneral mode={userManagementMode} />,
                          handle: {
                            breadcrumb: BreadcrumbRouteName.USER_PROFILE_GENERAL,
                          },
                        },
                        {
                          path: RoutePath.SETTINGS_USER_MANAGEMENT_PREFERENCES,
                          element: <UserManagementPreferences isReadOnly={!isUserManagementEditable} />,
                          handle: {
                            breadcrumb: BreadcrumbRouteName.USER_PROFILE_PREFERENCES,
                          },
                        },
                        {
                          path: RoutePath.SETTINGS_USER_MANAGEMENT_SECURITY,
                          element: <UserManagementSecurity />,
                          handle: {
                            breadcrumb: BreadcrumbRouteName.USER_PROFILE_SECURITY,
                          },
                        },
                        {
                          path: `${RoutePath.SETTINGS_USER_MANAGEMENT_PROFILE}`,
                          element: <UserManagementDefaultPage />,
                        },
                        {
                          path: `${RoutePath.SETTINGS_USER_MANAGEMENT_PROFILE}/*`,
                          element: <UserManagementDefaultPage />,
                        },
                      ],
                    },
                  ],
                },
                {
                  path: `${RoutePath.SETTINGS}/*`,
                  element: <Navigate replace to={RoutePath.NOT_FOUND} />,
                },
              ]
            : [],
        },
        {
          path: RoutePath.USER_PROFILE,
          handle: {
            breadcrumb: BreadcrumbRouteName.USER_PROFILE,
          },
          element: (
            <LoggedRoute name={PAGES.USER_PROFILE} topic={TOPICS.USER_PROFILE}>
              <UserProfileWrapper HeaderElement={<UserProfileTabs />}>
                <Outlet />
              </UserProfileWrapper>
            </LoggedRoute>
          ),
          children: [
            {
              path: RoutePath.USER_PROFILE,
              element: <Navigate replace to={RoutePath.USER_PROFILE_GENERAL} />,
            },
            {
              path: RoutePath.USER_PROFILE_GENERAL,
              element: <UserProfileGeneral />,
              handle: {
                breadcrumb: BreadcrumbRouteName.USER_PROFILE_GENERAL,
              },
            },
            {
              path: RoutePath.USER_PROFILE_PREFERENCES,
              element: <UserProfilePreferences />,
              handle: {
                breadcrumb: BreadcrumbRouteName.USER_PROFILE_PREFERENCES,
              },
            },
            {
              path: RoutePath.USER_PROFILE_SECURITY,
              element: <UserProfileSecurity />,
              handle: {
                breadcrumb: BreadcrumbRouteName.USER_PROFILE_SECURITY,
              },
            },
            {
              path: `${RoutePath.USER_PROFILE}/*`,
              element: <Navigate replace to={RoutePath.NOT_FOUND} />,
            },
          ],
        },
        isExportDataShown && {
          path: RoutePath.EXPORT_DATA,
          element: (
            <LoggedRoute name={PAGES.EXPORT_DATA} topic={TOPICS.EXPORT_DATA}>
              <ExportData authenticatedUser={user} />
            </LoggedRoute>
          ),
          handle: {
            breadcrumb: BreadcrumbRouteName.EXPORT_DATA,
            breadcrumbChildren: <ExportDataActions />,
          },
        },
        {
          path: RoutePath.SETTINGS,
          element: <Navigate replace to={RoutePath.SETTINGS_ORGANIZATION} />,
        },
        {
          path: `${RoutePath.SETTINGS}/*`,
          element: <Navigate replace to={RoutePath.NOT_FOUND} />,
        },
      ].filter(Boolean) as RouteObject[],
    },
    {
      path: RoutePath.RESET_PASSWORD_CONFIRM,
      element: (
        <PublicRoute name={PAGES.RESET_PASSWORD_CONFIRM} topic={TOPICS.AUTH}>
          <SetNewPassword />
        </PublicRoute>
      ),
    },
    {
      path: RoutePath.NOT_FOUND,
      element: (
        <PublicRoute name={PAGES.NOT_FOUND} topic={TOPICS.ERROR}>
          <NotFound />
        </PublicRoute>
      ),
    },
    {
      path: RoutePath.ALL,
      element: <Navigate to={RoutePath.HOME} />,
    },
  ]);

  return (
    <ThemeContextProvider setRoot themeConfiguration={firstDashboard?.themeConfiguration}>
      <>
        <ToastHandler />
        <RouterProvider router={router} />
        <CookiesModal />
      </>
    </ThemeContextProvider>
  );
};
