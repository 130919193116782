import { gql } from "@apollo/client";
import { type Installation, type Zone } from "@technis/shared";

const ZONE_FIELDS = gql`
  fragment zoneFields on Zone {
    id
    parentIds
    installation {
      timezone
    }
  }
`;

export const ZONES_BY_TAG = gql`
  ${ZONE_FIELDS}
  query zonesByTag($tagId: String!) {
    zonesByTag(tagId: $tagId) {
      ...zoneFields
    }
  }
`;

export const ZONES_BY_IDS = gql`
  query zonesByIds($zoneIds: [Int]!) {
    zonesByIds(zoneIds: $zoneIds) {
      id
      name
      installationId
      parentIds
    }
  }
`;

export const TOP_ZONES_WITH_TAGS = gql`
  query {
    topZonesWithTags {
      id
      installation {
        id
        name
      }
    }
  }
`;

export const ZONES_BY_INSTALLATION = gql`
  query zonesByInstallation($installationId: Int!) {
    zones: zonesByInstallation(installationId: $installationId) {
      id
      name
      childIds
      parentIds
    }
  }
`;

export const TOP_ZONE_BY_INSTALLATION = gql`
  query topZoneByInstallation($installationId: Int!) {
    topZone: topZoneByInstallation(installationId: $installationId) {
      id
    }
  }
`;

export type ZoneFieldResult = Pick<Zone, "id" | "parentIds"> & {
  installation: {
    timezone: string;
  };
};

export type ZonesByInstallationResult = {
  zones: {
    childIds: number[];
    id: number;
    name: string;
    parentIds: number[];
  }[];
};

export type ZonesByTagResult = { zonesByTag: ZoneFieldResult[] };

export type ZonesByIdsResult = { zonesByIds: Zone[] };

export type TopZonesWithTagsResult = {
  topZonesWithTags: (Zone & { installation?: Installation })[];
};

export type TopZoneByInstallationResult = { topZone: { id: number } };
