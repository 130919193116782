import { gql } from "@apollo/client";
import { type Action, type Alert, type Device, type Planning } from "@technis/shared";

import { type AlertCondition } from "@common/interfaces/alert";

export const ALERT_DETAILS = gql`
  query alertDetails($alertId: Int!) {
    alertDetails: alertById(alertId: $alertId) {
      id
      name
      enabled
      actionIds
      conditionIds
      planningIds
      organizationId
      plannings {
        id
        name
        phones
        mails
        userIds
      }
      conditions {
        id
        type
        value
        kinds
        padIds
        zoneId
        eventId
        direction
        maxTrigger
        padStatusCodes
        min
        max
        deviceAtmosphereIds
      }
      actions {
        id
        type
        reminder
        msgContent
        msgTitle
      }
    }
  }
`;

export const ALERT_EVENT = gql`
  query AlertBinding($eventId: Int!) {
    event: eventById(eventId: $eventId) {
      id
      name
      stopngo {
        limit
      }
      metrics
      installationId
    }
  }
`;

export const METRIC_DEVICES_LIST = gql`
  query metricDevicesList($installationId: Int!) {
    airQualityDevices: devicesAtmosphereByInstallation(installationId: $installationId) {
      name
      id
    }
    countingDevices: padsByInstallation(installationId: $installationId) {
      name
      id
    }
  }
`;

export const CREATE_ALERT = gql`
  mutation createAlertWithDetails(
    $name: String!
    $organizationId: Int!
    $conditionsToAdd: [ConditionInput]!
    $actionsToAdd: [ActionInput]!
    $planningToAdd: PlanningInput!
    $planningIds: [Int]
  ) {
    createAlertWithDetails(
      name: $name
      organizationId: $organizationId
      conditionsToAdd: $conditionsToAdd
      actionsToAdd: $actionsToAdd
      planningToAdd: $planningToAdd
      planningIds: $planningIds
    ) {
      id
      name
      enabled
      conditionIds
      actionIds
      planningIds
    }
  }
`;

export const UPDATE_ALERT_WITH_DETAILS = gql`
  mutation updateAlertWithDetails(
    $id: Int!
    $name: String!
    $organizationId: Int!
    $conditions: [ConditionInput]
    $actions: [ActionInput]
    $planning: PlanningInput
    $planningIds: [Int]
  ) {
    updateAlertWithDetails(
      id: $id
      name: $name
      organizationId: $organizationId
      conditions: $conditions
      actions: $actions
      planning: $planning
      planningIds: $planningIds
    ) {
      id
      name
      enabled
      conditionIds
      actionIds
      planningIds
    }
  }
`;

export const ALERT_CONNECTION = gql`
  query alertConnection($after: String, $alertName: String, $sort: SortConnectionAlertSearchEnum) {
    items: alertConnection(first: 15, after: $after, filter: { searchValue: $alertName }, sort: $sort) {
      count
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
          enabled
          installations {
            name
          }
          events {
            name
          }
          conditions {
            type
            value
            min
            max
          }
          actions {
            type
          }
          plannings {
            phones
            mails
            userIds
          }
        }
      }
    }
  }
`;

export const TOGGLE_ALERT = gql`
  mutation updateAlert($id: Int!, $enabled: Boolean!) {
    updateAlert(id: $id, enabled: $enabled) {
      name
      enabled
    }
  }
`;

export const DELETE_ALERT = gql`
  mutation deleteAlert($id: Int!) {
    deleteAlert(id: $id)
  }
`;

export type MetricDevicesListResult = {
  airQualityDevices: Pick<Device, "id" | "name">[];
  countingDevices: Pick<Device, "id" | "name">[];
};

export type AlertEventResult = {
  event: {
    id: number;
    installationId: number;
    metrics: string[];
    name: string;
    stopngo: {
      limit: number;
    };
  };
};

export type AlertDetailsResult = {
  alertDetails: Alert & {
    actions: Action[];
    conditions: AlertCondition & { eventId: number }[];
    plannings: Planning[];
  };
};
