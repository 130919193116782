import React from "react";
import { Navigate } from "react-router-dom";

import { PageLoader } from "@components/PageLoader";

import { RoutePath } from "./routePath";

export interface SensorRouteProps {
  children: JSX.Element;
  isAirQualityShown: boolean | null;
  isCountingShown: boolean | null;
  requestedRoute: string;
}

export const SensorRoute = ({ children, requestedRoute, isCountingShown, isAirQualityShown }: SensorRouteProps): JSX.Element => {
  const isLoading = [isCountingShown, isAirQualityShown].some((predicate) => predicate == null);

  const isAuthorized =
    (requestedRoute.includes(RoutePath.COUNTING) && isCountingShown) ||
    (requestedRoute.includes(RoutePath.AIR_QUALITY) && isAirQualityShown);

  if (isAuthorized) {
    return children;
  }

  return isLoading ? <PageLoader /> : <Navigate replace to={RoutePath.HOME} />;
};
