import { type AnyAction, combineReducers, type Reducer } from "@reduxjs/toolkit";

import { alertReducer, initialState as initialAlertState } from "@redux/alert/alert.slice";
import { breadcrumbsReducer, initialState as breadcrumbsState } from "@redux/breadcrumbs/breadcrumbs.slice";
import { chosenFiltersReducer, chosenFiltersState } from "@redux/chosenFilters/chosenFilters.slice";
import { exitModalReducer, initialState as exitModalInitialState } from "@redux/exitModal/exitModal.slice";
import { exportDataReducer, initialState as exportDataInitialState } from "@redux/exportData/exportData.slice";
import { formValidationReducer, initialState as formValidationInitialState } from "@redux/formValidation/formValidation.slice";
import { initialState as installationState, installationReducer } from "@redux/installation/installation.slice";
import { initialState as initialTutorialState, tutorialReducer as tutorial } from "@redux/tutorial/tutorial.slice";

import { authReducer as auth, initialState as initialAuthState } from "../redux/auth/auth.slice";
import { globalFilterReducer as globalFilter, initialState as initialGlobalFilterState } from "../redux/globalFilter/globalFilter.slice";
import { userOnboardingReducer, userOnboardingState } from "../redux/onboarding";
import { initialState as initialOrganizationState, organizationReducer as organization } from "../redux/organization/organization.slice";
import { initialState as initialToastState, toastReducer as toast } from "../redux/toast/toast.slice";
import { initialState as initialTranslationState, translationReducer as translation } from "../redux/translation/translation.slice";
import { initialState as initialUserState, userReducer as user } from "../redux/user/user.slice";

export const initialRootState = {
  auth: initialAuthState,
  toast: initialToastState,
  tutorial: initialTutorialState,
  globalFilter: initialGlobalFilterState,
  translation: initialTranslationState,
  userOnboarding: userOnboardingState,
  chosenFilters: chosenFiltersState,
  breadcrumbs: breadcrumbsState,
  installation: installationState,
  user: initialUserState,
  exitModal: exitModalInitialState,
  exportData: exportDataInitialState,
  formValidation: formValidationInitialState,
  alert: initialAlertState,
  organization: initialOrganizationState,
};

export type RootState = typeof initialRootState;

export const rootReducer: Reducer<RootState, AnyAction> = combineReducers({
  auth,
  toast,
  globalFilter,
  translation,
  tutorial,
  userOnboarding: userOnboardingReducer,
  chosenFilters: chosenFiltersReducer,
  breadcrumbs: breadcrumbsReducer,
  alert: alertReducer,
  formValidation: formValidationReducer,
  installation: installationReducer,
  user,
  organization,
  exitModal: exitModalReducer,
  exportData: exportDataReducer,
});
