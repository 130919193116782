import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";

import { authMiddleware } from "@redux/auth/auth.middleware";

import { rootReducer } from "./rootReducer";

export const persistConfig = {
  key: "root",
  storage,
  blacklist: ["onboarding", "breadcrumbs", "tutorial", "user", "formValidation", "exportData", "exitModal", "alert", "auth"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const middlewares = [thunk, authMiddleware];

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [...getDefaultMiddleware({ serializableCheck: false }), ...middlewares],
});

const persistor = persistStore(store);

export { persistor, store };
