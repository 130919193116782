import { compose, type Dispatch } from "@reduxjs/toolkit";
import { Button, ComponentSize, Heading } from "@technis/ui";
import { type TFunction } from "i18next";
import React, { type ClassicComponentClass, Component, type ErrorInfo, type ReactNode } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";

import { translation } from "@lang/translation";

import { logout } from "@redux/auth/auth.slice";

import { RoutePath } from "@routes/routePath";

interface OwnProps {
  children?: ReactNode;
  t: TFunction;
}

interface DispatchProps {
  logout(): void;
}

interface State {
  hasError: boolean;
  isLoggingOut: boolean;
}

type Props = OwnProps & DispatchProps;

export class BaseErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false, isLoggingOut: false };
  }

  public static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error("Uncaught error:", error, errorInfo);
  }

  reload = (): void => window.location.reload();

  logout = (): void => {
    this.setState({ isLoggingOut: true }, () => {
      this.props.logout();
    });
  };

  render(): ReactNode {
    const { isLoggingOut, hasError } = this.state;
    if (isLoggingOut) return <Navigate to={RoutePath.LOGIN} />;

    if (hasError) {
      return (
        <div className="errorBoundary">
          <Heading size={ComponentSize.LARGE}>{this.props.t(translation.errors.unHandled)}</Heading>

          <div className="errorBoundary--buttons">
            <Button size={ComponentSize.MEDIUM} onClick={this.reload}>
              {this.props.t(translation.common.reload)}
            </Button>

            <Button size={ComponentSize.MEDIUM} isLoading={isLoggingOut} disabled={isLoggingOut} onClick={this.logout}>
              {this.props.t(translation.common.logout)}
            </Button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

const mapDispatchToProps = (dispatch: Dispatch): { logout: () => void } => ({
  logout: () => dispatch(logout()),
});

const enhance = compose<ClassicComponentClass>(withTranslation(), connect<null, DispatchProps, OwnProps>(null, mapDispatchToProps));

export const ErrorBoundary = enhance(BaseErrorBoundary);
