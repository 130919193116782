enum RoutePath {
  ROOT = "/",
  LOGIN = "/login",
  HOME = "/home",
  HOME2 = "/home2",
  PAGE = "/dashboard/:dashboardId/page/:pageId",
  COUNTING = "/counting",
  COUNTING_INSTALLATION = "/counting/:installationId",
  AIR_QUALITY = "/air-quality",
  AIR_QUALITY_INSTALLATION = "/air-quality/:installationId",
  NOT_FOUND = "/not-found",
  ALL = "*",
  INVITATION = "/invitation",
  ONBOARDING = "/onboarding",
  ONBOARDING_ORG_SETTINGS = "/onboarding/org-settings",
  ONBOARDING_ABOUT = "/onboarding/about",
  ONBOARDING_PASSWORD = "/onboarding/password",
  ONBOARDING_PROFILE_SETTINGS = "/onboarding/profile-settings",
  ONBOARDING_SECURITY = "/onboarding/security",
  RESET_PASSWORD_CONFIRM = "/reset-password-confirm",
  SHORT_LINK = "/t/:code",
  HELP_CENTER = "/help",
  TUTORIAL = "/tutorial",
  SETTINGS = "/settings",
  SETTINGS_ALERTS = "/settings/alerts",
  SETTINGS_ALERTS_NEW = "/settings/alerts/new-alert",
  SETTINGS_ALERTS_UPDATE = "/settings/alerts/:id",
  USER_PROFILE = "/user-profile",
  USER_PROFILE_GENERAL = "/user-profile/general",
  USER_PROFILE_PREFERENCES = "/user-profile/preferences",
  USER_PROFILE_SECURITY = "/user-profile/security",
  SETTINGS_USER_MANAGEMENT = "/settings/user-management",
  SETTINGS_USER_MANAGEMENT_PROFILE = "/settings/user-management/:id",
  SETTINGS_USER_MANAGEMENT_GENERAL = "/settings/user-management/:id/general",
  SETTINGS_USER_MANAGEMENT_PREFERENCES = "/settings/user-management/:id/preferences",
  SETTINGS_USER_MANAGEMENT_SECURITY = "/settings/user-management/:id/security",
  EXPORT_DATA = "/export-data",
  SETTINGS_ORGANIZATION = "/settings/organization",
}

export { RoutePath };
