import { gql } from "@apollo/client";
import { type ProfileRights } from "@technis/shared";

export const PROFILE_DATA_QUERY = gql`
  query profileById($id: Int!) {
    profileById(id: $id) {
      rights {
        organization {
          user {
            read
            edit
            delete
          }
          general {
            read
            edit
          }
        }
        dashboard {
          custom {
            read
          }
          sensor {
            read
          }
          alert {
            read
            delete
            create
            edit
          }
        }
      }
    }
  }
`;

export type Lang = "en";

export interface ProfileData {
  profileById: {
    rights: ProfileRights;
  };
}
