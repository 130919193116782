import { type Page, WEEK_IN_MILLISECONDS, type Widget } from "@technis/shared";
import { isEmpty } from "lodash";

const SAMSUNG_POPUP_PAGE_ID = 34;
const BLUEWATER_PAGES = [116, 117, 118];
const OUTFORM_PAGES = [18, 19, 20, 21];

export const needsDayDwell = (widgetId: Widget["id"], timeRange: number): boolean => widgetId === 274 && timeRange < WEEK_IN_MILLISECONDS;

export const shouldShowReportGenerator = (
  pageId: Page["id"],
  whiteList: Page["id"][] = [...OUTFORM_PAGES],
  blackList: Page["id"][] = [SAMSUNG_POPUP_PAGE_ID, ...BLUEWATER_PAGES],
): boolean => {
  if (!isEmpty(whiteList) && whiteList.includes(pageId)) {
    return true;
  } else if (!isEmpty(blackList) && blackList.includes(pageId)) {
    return false;
  }

  return false;
};
