import { gql } from "@apollo/client";
import { type Event, type EventMetrics } from "@technis/shared";

export const ALL_EVENTS_QUERY = gql`
  query allEvents {
    allEvents {
      metrics
    }
  }
`;

export const EVENT_CONNECTION = gql`
  query eventConnection($installationId: Float!) {
    eventConnection(filter: { installationId: $installationId }) {
      edges {
        node {
          id
          name
          stopngo {
            limit
          }
          metrics
        }
      }
    }
  }
`;

export type AllEventsQueryData = { allEvents: Array<Event> };

export type EventConnectionResult = {
  eventConnection: {
    edges: {
      node: {
        id: number;
        metrics: EventMetrics[];
        name: string;
        stopngo: {
          limit: number;
        };
      };
    }[];
  };
};
