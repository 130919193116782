import {
  type AggregationWidget,
  type CustomWidget,
  CustomWidgetTypes,
  type Genders,
  KpiRetailGroupType,
  type LiveWidget,
  type Metric,
  MetricAggregations,
  MINUTE_IN_MILLISECONDS,
  Orders,
  type Page,
  RealTime,
  type RetailGroupBy,
  RetailMetrics,
  type WidgetGlobalFilter,
  WidgetKinds,
} from "@technis/shared";
import { type AppColor } from "@technis/ui";
import { type TFunction } from "i18next";
import { capitalize, clone, get, sortBy, unset } from "lodash";

import { type TagDictionary } from "@common/Tag";
import { type AppTheme } from "@common/Theme";
import { type Widget } from "@common/types";

import { translation } from "@lang/translation";

import { type PieChartDataPart } from "../typings";

export const NOT_IMPLEMENTED = "not implemented";

const POLL: Record<RealTime, number> = {
  [RealTime.SLOW_POLL]: 5 * MINUTE_IN_MILLISECONDS,
  [RealTime.FAST_POLL]: MINUTE_IN_MILLISECONDS,
  [RealTime.LIVE]: 0,
};

export const isObject = <T>(value: T): boolean => value !== null && typeof value === "object";

export const omitDeep = <T>(value: T, keys: string[] | string): T | undefined => {
  const clonedValue = clone(value);
  if (clonedValue === undefined) {
    return;
  }

  if (Array.isArray(clonedValue)) {
    for (let index = 0; index < clonedValue.length; index += 1) {
      clonedValue[index] = omitDeep(clonedValue[index], keys);
    }
    return clonedValue;
  }

  if (!isObject(clonedValue)) {
    return clonedValue;
  }

  if (typeof keys === "string") {
    keys = [keys];
  }

  if (!Array.isArray(keys)) {
    return clonedValue;
  }

  for (const key of keys) {
    unset(clonedValue, key);
  }

  for (const key in clonedValue) {
    if (clonedValue.hasOwnProperty(key)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      clonedValue[key] = omitDeep(clonedValue[key], keys);
    }
  }

  return clonedValue;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeUndefined = (object: Record<string, any>): Record<string, any> => {
  if (object) {
    Object.keys(object).forEach((key) => {
      if (object[key] === undefined) {
        delete object[key];
      } else if (typeof object[key] === "object") {
        object[key] = removeUndefined(object[key]);
      }
    });
  }
  return object;
};

export const sortByOrder = <T extends Page | Widget | LiveWidget | WidgetGlobalFilter>(entities: T[]): T[] => sortBy(entities, "order");

export const removeIndexFromKey = (key: string, index: number): string => key.replace(`_${index}`, "");

export const capitalizeFirstLetter = (string: string): string => string.charAt(0).toUpperCase() + string.slice(1);

export const removeDuplicatesString = (array: string[]): string[] => array.filter((value, index, self) => index === self.indexOf(value));

export const getMetricLabel = (metric: Metric, tagDictionary: TagDictionary, translate: TFunction): string | undefined => {
  if (metric?.configuration?.customTitle) {
    return metric.configuration.customTitle;
  }

  const type = RetailMetrics[metric.type];
  const metricType = translate(translation.common.metrics[type]);
  const tagCategoryId = metric.groupBy?.find((item) => item.type === KpiRetailGroupType.TAG_CATEGORY)?.tagId;
  const category = tagCategoryId ? capitalize(tagDictionary[tagCategoryId]) : "";

  if (metric.limit) {
    if (metric.limit === 1 && metric.sortBy) {
      return translate(metric.sortBy.order === Orders.DESC ? translation.metricLabel.highest : translation.metricLabel.lowest, {
        metricType,
        category,
      });
    }

    return translate(translation.metricLabel.limit, {
      limit: metric.limit,
      metricType,
      category,
    });
  }

  const aggregation = get(metric, "groupBy[0].aggregation");

  if (
    aggregation &&
    ![MetricAggregations.COUNT, MetricAggregations.PERCENTAGE, MetricAggregations.WEIGHTED_PERCENTAGE].includes(aggregation)
  ) {
    const { aggregation } = (metric.groupBy as RetailGroupBy[])[0]; // TODO: remove "as RetailGroupBy[]" when groupBy in Metric becomes required
    return translate(translation.metricLabel[aggregation as MetricAggregations], {
      metricType,
    }); // TODO: remove "as MetricAggregations" when aggregation in RetailGroupBy becomes required
  }
};

export const getPieChartColors = (
  groupBy: RetailGroupBy,
  pieChartData: (PieChartDataPart & { keyName: string })[],
  theme: AppTheme,
): AppColor[] => {
  if (groupBy.type === KpiRetailGroupType.GENDER && theme.genderColors)
    return pieChartData.map(({ keyName }) => theme.genderColors[keyName as Genders]) as AppColor[];
  console.log(`${groupBy.type} ${NOT_IMPLEMENTED}`);
  return [];
};

export const isWidget =
  <T extends Widget>(widgetType: Widget["type"]) =>
  (widget: Omit<Widget, "id">): widget is T =>
    widget.type === widgetType;

export const getPollingInterval = (realTime?: RealTime): number => POLL[realTime ?? RealTime.SLOW_POLL];

export const isLiveWidget = (widget: Widget): widget is LiveWidget => widget.kind === WidgetKinds.LIVE;
export const isAggregationWidget = (widget: Widget): widget is AggregationWidget => widget.kind === WidgetKinds.AGGREGATION;
export const isCustomWidget = (widget: Widget): widget is CustomWidget => widget.kind === WidgetKinds.CUSTOM;
export const isCustomWidgetByType = (widget: Widget): widget is CustomWidget =>
  (Object.values(CustomWidgetTypes) as unknown as string[]).includes(widget.type);

export const mergeObjectsByKeys = (objects: Record<string, string>[], keys: string[]): Record<string, string>[] => {
  const mergedObjects: Record<string, string>[] = [];

  for (const object of objects) {
    const matchingIndex = mergedObjects.findIndex((mergedObject) => keys.every((key) => mergedObject[key] === object[key]));

    if (matchingIndex === -1) {
      mergedObjects.push(object);
    } else {
      mergedObjects[matchingIndex] = { ...mergedObjects[matchingIndex], ...object };
    }
  }

  return mergedObjects;
};
