import { gql } from "@apollo/client";
import { type EventMetrics, type ExportTarget } from "@technis/shared";

export const EXPORT_DATA = gql`
  query ExportData(
    $dataType: EventMetrics!
    $from: String!
    $to: String!
    $userIds: [String!]!
    $granularity: Float!
    $includeSubZones: Boolean!
    $target: ExportTarget!
    $installationId: Float!
    $zoneIds: [Float!]!
  ) {
    sendXLSXReport(
      report: {
        dataType: $dataType
        from: $from
        to: $to
        userIds: $userIds
        granularity: $granularity
        includeSubZones: $includeSubZones
        target: $target
        installationId: $installationId
        zoneIds: $zoneIds
      }
    )
  }
`;

export interface ExportDataQueryPayload {
  dataType: EventMetrics;
  from: string;
  granularity: number;
  includeSubZones: boolean;
  installationId: number;
  target: ExportTarget;
  to: string;
  userIds: string[];
  zoneIds: number[];
}
