import { type ApolloQueryResult, gql } from "@apollo/client";

import { client, type Mutation } from ".";

const LOGIN_QUERY = gql`
  query login($email: String!, $password: String!, $deviceId: String) {
    login(email: $email, password: $password, deviceId: $deviceId)
  }
`;

export const LOGIN_WITH_OTP_TOKEN_QUERY = gql`
  query loginWithOtpToken($otp: String!) {
    loginWithOtpToken(otp: $otp)
  }
`;

const RENEW_TOKEN_QUERY = gql`
  query renewToken {
    renew
  }
`;

export const REGISTER_BROWSER_QUERY = gql`
  mutation registerBrowser {
    registerBrowser
  }
`;

export type LoginQueryResult = ApolloQueryResult<{ login: string }>;
export type LoginWithOtpTokenQueryResult = { loginWithOtpToken: string };
export type RenewTokenQueryResult = ApolloQueryResult<{ renew: string }>;
// export type ChangePasswordQueryResult = FetchResult<{ updatePassword: string }>;

export const AuthService = {
  login: ({ email, password, deviceId }: { deviceId?: string; email: string; password: string }): Promise<LoginQueryResult> =>
    client.query(LOGIN_QUERY, { email, password, deviceId }, { fetchPolicy: "network-only" }),
  registerBrowser: (): Promise<Mutation> => client.mutate(REGISTER_BROWSER_QUERY),
  renewToken: (): Promise<RenewTokenQueryResult> => client.query(RENEW_TOKEN_QUERY),
};
