import { useQuery } from "@apollo/client";
import { type ErrorResponse } from "@apollo/client/link/error";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import i18next from "i18next";
import { get, isEmpty } from "lodash";
import React, { Suspense, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

import { useViewTracking } from "@hooks/useTracking";

import { allApolloErrorsToString } from "@api/utils";

import { COOKIE_AUTH_VIEW_AS_USER, getAuthCookie, getCookie } from "@common/helpers/cookie";

import { PageLoader } from "@components/PageLoader";

import { loginError, loginSuccess } from "@redux/auth/auth.slice";

import { type RouteProps } from "@routes/PublicRoute";

import { AuthService } from "@services/authService";
import { ME_DATA_QUERY, type MeQueryData } from "@services/userService";

import { type RootState } from "@store/rootReducer";

import { RoutePath } from "./routePath";

export const LoggedRoute = ({ children, topic, name }: RouteProps): JSX.Element => {
  const location = useLocation();
  const dispatch = useDispatch();

  useViewTracking(topic, name);

  const [shouldBeRedirectedToLoginPage, setShouldBeRedirectedToLoginPage] = useState(false);

  const isLoggedIn = useSelector((state: RootState) => state.auth.isLoggedIn);
  const token = getAuthCookie();
  const isViewAs = !!getCookie(COOKIE_AUTH_VIEW_AS_USER);

  const { data } = useQuery<MeQueryData>(ME_DATA_QUERY, { fetchPolicy: "network-only" });
  const meData = data?.me;
  const auth = getAuth();

  useEffect(() => {
    i18next.changeLanguage(meData?.lang);
  }, [meData?.lang]);

  useEffect(() => {
    const handleAuth = async (): Promise<void> => {
      try {
        if (!isLoggedIn && token) {
          if (isViewAs) {
            await signInWithCustomToken(auth, token);
            dispatch(loginSuccess(token));
          } else {
            const renewData = await AuthService.renewToken();
            const newToken = renewData.data.renew;
            await signInWithCustomToken(auth, newToken);
            dispatch(loginSuccess(newToken));
          }
        } else {
          setShouldBeRedirectedToLoginPage(true);
        }
      } catch (error) {
        const isApolloError = !isEmpty(get(error, "graphQLErrors"));
        const errorMessage = isApolloError ? allApolloErrorsToString(error as ErrorResponse) : (error as Error).message;
        dispatch(loginError(errorMessage));
        setShouldBeRedirectedToLoginPage(true);
      }
    };
    handleAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (isLoggedIn) {
    return <Suspense fallback={<PageLoader loading />}>{children}</Suspense>;
  }

  if (shouldBeRedirectedToLoginPage) {
    return <Navigate replace to={RoutePath.LOGIN} state={{ from: location }} />;
  }

  return <PageLoader />;
};
