import { type ApolloQueryResult, gql } from "@apollo/client";
import { type Dashboard } from "@technis/shared";

export const ALL_DASHBOARDS_QUERY = gql`
  query allDashboards {
    allDashboards {
      id
      userId
      organizationId
      name
      icon
      color
      themeConfiguration {
        primaryColor
        secondaryColor
        backgroundColor
        highlightColor
        fontColor
        kpiColors {
          DWELL
          CAPTURE_RATE
          POPULARITY
          AFFLUENCE_IN
          AFFLUENCE_OUT
          INSIDE
          GROUP_SIZE_IN
          GROUP_SIZE_OUT
          N_GROUPS_IN
          N_GROUPS_OUT
          OCCUPANCY
          AFFLUENCE_IN_QUEUE
          AFFLUENCE_OUT_QUEUE
          INSIDE_QUEUE
          DWELL_QUEUE
          ABANDON_RATE_QUEUE
          TEMPERATURE
          HUMIDITY_LEVEL
          CO2_LEVEL
        }
        genderColors {
          MALE
          FEMALE
        }
        charts {
          columnColors
          seriesColors
          stackedColors
        }
      }
    }
  }
`;

export type AllDashboardQueryData = { allDashboards: Dashboard[] };

export type AllDashboardQueryResult = ApolloQueryResult<AllDashboardQueryData>;
