import { gql } from "@apollo/client";

export const ACCEPT_INVITATION_2FA_EMAIL_MUTATION = gql`
  mutation AcceptInvitation2FaEmail(
    $token: String!
    $password: String!
    $organizationName: String
    $organizationAddress: String
    $organizationLogo: String
    $firstName: String!
    $lastName: String!
    $phone: String
    $picture: String
    $lang: String
    $theme: String
    $timezone: String
    $dateFormat: String
    $timeFormat: String
    $otpContact: OtpContactType!
  ) {
    updateInvitationOtpContact(token: $token, otpContact: $otpContact)
    acceptInvitation(
      token: $token
      password: $password
      organizationName: $organizationName
      organizationAddress: $organizationAddress
      organizationLogo: $organizationLogo
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      picture: $picture
      lang: $lang
      theme: $theme
      timezone: $timezone
      dateFormat: $dateFormat
      timeFormat: $timeFormat
    )
  }
`;

export type AcceptInvitation2FaEmailResult = {
  acceptInvitation?: boolean | null;
  updateInvitationOtpContact?: boolean | null;
};

export const ACCEPT_INVITATION_MUTATION = gql`
  mutation AcceptInvitation(
    $token: String!
    $password: String!
    $organizationName: String
    $organizationAddress: String
    $organizationLogo: String
    $firstName: String!
    $lastName: String!
    $phone: String
    $picture: String
    $lang: String
    $theme: String
    $timezone: String
    $dateFormat: String
    $timeFormat: String
  ) {
    acceptInvitation(
      token: $token
      password: $password
      organizationName: $organizationName
      organizationAddress: $organizationAddress
      organizationLogo: $organizationLogo
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      picture: $picture
      lang: $lang
      theme: $theme
      timezone: $timezone
      dateFormat: $dateFormat
      timeFormat: $timeFormat
    )
  }
`;

export type AcceptInvitationResult = { acceptInvitation?: boolean | null };

export const SEND_INVITATION_VERIFICATION_CODE_MUTATION = gql`
  mutation SendInvitationVerificationCode($token: String!, $phone: String!) {
    sendInvitationVerificationCode(token: $token, phone: $phone)
  }
`;

export type SendInvitationVerificationCodeResult = { sendInvitationVerificationCode?: boolean | null };
