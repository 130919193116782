import { type ApolloQueryResult, gql } from "@apollo/client";

import { client, type Mutation } from ".";

const CREATE_RETAIL_REPORT_QUERY = gql`
  mutation createRetailReport($from: Float!, $to: Float!, $zoneIds: [Int!], $granularity: Int!, $emails: [String!]) {
    createRetailReport(from: $from, to: $to, zoneIds: $zoneIds, granularity: $granularity, emails: $emails)
  }
`;

export type CreateRetailReportQueryResult = ApolloQueryResult<{
  createRetailReport: string;
}>;

export const RetailReportGeneratorService = {
  create: (from: number, to: number, zoneIds: number[], granularity: number, emails: string[]): Promise<Mutation> =>
    client.mutate(CREATE_RETAIL_REPORT_QUERY, {
      from,
      to,
      zoneIds,
      granularity,
      emails,
    }),
};
