import React, { Suspense } from "react";

import { useViewTracking } from "@hooks/useTracking";

import { PageLoader } from "@components/PageLoader";

export interface RouteProps {
  children: JSX.Element;
  name: string;
  topic: string;
}
export const PublicRoute = ({ children, topic, name }: RouteProps): JSX.Element => {
  useViewTracking(topic, name);

  return <Suspense fallback={<PageLoader loading />}>{children}</Suspense>;
};
