import { gql } from "@apollo/client";
import { type Passage } from "@technis/shared";

export const PASSAGES_BY_IDS = gql`
  query passagesByIds($passageIds: [Int]!) {
    passagesByIds(passageIds: $passageIds) {
      id
      name
    }
  }
`;

export type PassagesByIdsResult = { passagesByIds: Passage[] };
