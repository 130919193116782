import { type ApolloQueryResult, gql } from "@apollo/client";
import { type Installation, type Organization } from "@technis/shared";

import { client } from ".";

const organizationStatsData = `
  installationCount
  userCount
  padCount
  deviceAtmosphereCount
  totalDeviceCount
`;

export const ORGANIZATION_STATS_DATA_QUERY = gql`
  query organizationStatsData {
    organizationStats {
      ${organizationStatsData}
    }
  }
`;

export const ORGANIZATION_DATA_QUERY = gql`
  query organizationData($organizationId: Int!) {
    organizationById(organizationId: $organizationId) {
      name
      logo
      address
      installationIds
    }
  }
`;

export const UPDATE_ORGANIZATION = gql`
  mutation updateOrganizationData($id: Int!, $name: String, $address: String) {
    updateOrganization(id: $id, address: $address, name: $name) {
      id
    }
  }
`;

export interface OrganizationData {
  organizationById: Pick<Organization, "name" | "logo" | "address"> & { installationIds?: Installation["id"][] };
}

export interface OrganizationStatsData {
  deviceAtmosphereCount: number;
  installationCount: number;
  padCount: number;
  totalDeviceCount: number;
  userCount: number;
}

export type OrganizationStatsQueryData = {
  organizationStats: OrganizationStatsData;
};

export type OrganizationDataQueryResult = ApolloQueryResult<OrganizationData>;

export const OrganizationService = {
  organizationData: ({ organizationId }: { organizationId: number }): Promise<OrganizationDataQueryResult> =>
    client.query(ORGANIZATION_DATA_QUERY, { organizationId }),
};
