import React, { type FC } from "react";

import { HomePageV2Kpis } from "@components/HomePageV2Kpis/HomePageV2Kpis";
import { InteractiveMapWidget } from "@components/InteractiveMap/InteractiveMapWidget/InteractiveMapWidget";
import { PageScrollableArea } from "@components/ScrollableArea/PageScrollableArea";

import styles from "./styles.module.scss";

export const DashboardV2: FC = () => (
  <PageScrollableArea>
    <div className={styles.wrapper}>
      <HomePageV2Kpis />
      <InteractiveMapWidget />
    </div>
  </PageScrollableArea>
);
