import { gql } from "@apollo/client";
import { type Page, type Widget, type WidgetGlobalFilter } from "@technis/shared";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MAPVIEW_CONFIGURATION_FIELDS = gql`
  fragment MapviewFields on MapViewWidgetConfiguration {
    elements {
      zoneId
      x
      y
      width
      height
      tooltipPosition
      textColor
      backgroundColor
    }
    backgrounds
  }
`;

const BASE_WIDGET_CONFIGURATION_FIELDS = `
  hideUnit
  useCustomFormatter
`;

const WIDGET_FIELDS = gql`
  fragment widgetFields on Widget {
    id
    pageId
    title
    type
    category
    tooltip
    realTime
    order
    ignoredFilters
    zoneIds
    passageIds
    deviceIds
    alertIds
    kind
    isKpiMenuHidden
    headers {
      type
    }
    metrics {
      complementary
      type
      limit
      customTitle
      customSubTitle
      filters {
        conditions
        operator
      }
      granularity
      sortBy {
        type
        order
      }
      groupBy {
        aggregation
        type
        tagId
        tagIds
        granularity
      }
      target
      passageType
      configuration {
        ... on BaseMetricConfiguration {
          hideUnit
          useCustomFormatter
        }
        ... on KpiTableMetricConfiguration {
          hideUnit
          useCustomFormatter
          customTitle
        }
        ... on KpiMetricConfiguration {
          hideUnit
          useCustomFormatter
          customTitle
          customSubTitle
        }
      }
    }
    ignoredFilters
    configuration {
      ... on BaseWidgetConfiguration {
        ${BASE_WIDGET_CONFIGURATION_FIELDS}
      }
      ... on BarWidgetConfiguration {
        stacked
        isPercentage
        ${BASE_WIDGET_CONFIGURATION_FIELDS}
      }
      ... on CircleWidgetConfiguration {
        halfCircle
        showLegend
        ${BASE_WIDGET_CONFIGURATION_FIELDS}
      }
      ... on ProgressBarWidgetConfiguration {
        showMinMaxLabel
        ${BASE_WIDGET_CONFIGURATION_FIELDS}
      }
      ... on WebChartWidgetConfiguration {
        isParallelCoordinates
        ${BASE_WIDGET_CONFIGURATION_FIELDS}
      }
      ... on InstallationWidgetConfiguration {
        installationId
        ${BASE_WIDGET_CONFIGURATION_FIELDS}
      }
      ... on KpiWidgetConfiguration {
        isKpiMenuHidden
        ${BASE_WIDGET_CONFIGURATION_FIELDS}
      }
    }
    hideUnit
    useCustomFormatter
    colorimetryConfig {
      type
      colorimetry
    }
    hideUnit
    useCustomFormatter
    colorimetryConfig {
      type
      colorimetry
    }
  }
`;

const WIDGET_GLOBAL_FILTER_DATA = gql`
  fragment WidgetGlobalFilter on WidgetGlobalFilter {
    id
    pageId
    type
    categoryId
    order
    zoneIds
  }
`;

export const PAGES_BY_DASHBOARD_ID = gql`
  ${WIDGET_FIELDS}
  ${WIDGET_GLOBAL_FILTER_DATA}
  query pagesByDashboardId($id: Int!) {
    pagesByDashboardId(id: $id) {
      id
      dashboardId
      name
      icon
      order
      widgets {
        ...widgetFields
      }
      widgetGlobalFilters {
        ...WidgetGlobalFilter
      }
    }
  }
`;

export const PAGE_BY_ID = gql`
  ${WIDGET_FIELDS}
  ${WIDGET_GLOBAL_FILTER_DATA}
  query pageById($id: Int!) {
    pageById(id: $id) {
      id
      dashboardId
      name
      icon
      widgets {
        ...widgetFields
      }
      widgetGlobalFilters {
        ...WidgetGlobalFilter
      }
    }
  }
`;

export type PagesByDashboardIdResult = {
  pagesByDashboardId: Array<Page & { widgetGlobalFilters: WidgetGlobalFilter[]; widgets: Widget[] }>;
};

export type PageByIdResult = {
  pageById: Page & {
    widgetGlobalFilters: WidgetGlobalFilter[];
    widgets: Widget[];
  };
};
